import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["slide"]

  connect() {
    this.keydownHandler = (event) => {
      if (event.keyCode == 39) {
        this.next()
      } else if (event.keyCode == 37) {
        this.previous()
      } else if (event.keyCode == 27) {
        this.close()
      }
    }

    document.addEventListener("keydown", this.keydownHandler)
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.keydownHandler)
  }

  next() {
    const current = this.active
    if (!current || this.slideTargets.length < 2) { return }

    const index = this.slideTargets.indexOf(current)
    const nextIndex = index == this.slideTargets.length - 1 ? 0 : index + 1
    this.toggleSlide(current, this.slideTargets[nextIndex])
  }

  previous() {
    const current = this.active
    if (!current || this.slideTargets.length < 2) { return }

    const index = this.slideTargets.indexOf(current)
    const nextIndex = index == 0 ? this.slideTargets.length - 1 : index - 1
    this.toggleSlide(current, this.slideTargets[nextIndex])
  }

  close() {
    const current = this.active
    if (current) { this.slideController(current).toggle(false) }
  }

  toggleSlide(current = null, target = null) {

    if (current !== null) {
      this.slideController(current).toggle(false)
    }

    if (target !== null){
      this.slideController(target).toggle(true)
    }
  }

  get active() {
    let slide = false

    for (let element of this.slideTargets) {
      const controller = this.slideController(element)
      slide = controller.activeValue ? element : false
      if (slide) { break }
    }

    return slide
  }

  slideController(element) {
    return this.application.getControllerForElementAndIdentifier(element, "slide")
  }
}
