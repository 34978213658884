import ApplicationController from "./../application_controller"
import { FetchRequest } from "@rails/request.js"

export default class extends ApplicationController {

  static values = {
    assignUrl: String,
    currentUrl: String
  }

  async assign(event) {
    const form = event.target.closest("form")
    const formData = new FormData(form)
    const url = new URL(form.action)

    const request = new FetchRequest(form.method, url.pathname, {
      body: formData,
      responseKind: "turbo-stream"
    })

    const response = await request.perform()
  }
}
