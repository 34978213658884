import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.clickHandler = (event) => {
      if (event.target.tagName.toLowerCase() == "a") {
        const url = new URL(event.target.href)

        if (
          url.origin !== location.origin ||
          /\.pdf$/i.test(url)
        ) {
          event.preventDefault()
          window.open(url.href, "_blank")
        }
      }
    }

    document.addEventListener("click", this.clickHandler)
  }

  disconnect() {
    document.removeEventListener("click", this.clickHandler)
  }
}
