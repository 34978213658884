import { Controller } from "stimulus"
import Cleave from "cleave.js"

export default class extends Controller {

  static values = {
    datePattern: Array,
    timePattern: Array,
    delimiter: String,
    delimiters: Array,
    blocks: Array
  }

  connect() {
    this.cleave = new Cleave(this.element, this.options)
  }

  get options() {
    return {
      date: this.datePatternValue.length ? true : false,
      datePattern: this.datePatternValue,
      time: this.timePatternValue.length ? true : false,
      timePattern: this.timePatternValue,
      delimiter: this.delimiterValue ? this.delimiterValue : "-",
      delimiters: this.delimitersValue,
      blocks: this.blocksValue
    }
  }
}
