// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"

Rails.start()
Turbolinks.start()

import "controllers"
import "css/index.css"

// import all images
const importAll = (r) => r.keys().map(r)
importAll(require.context("../images", false, /jpe?g|png|gif/))

import "lazysizes"

// set Turbo form mode to "opt-in"
// this way forms are not submitted via Turbo by default but can be by setting
// data=turbo="true" on the form element
Turbo.setFormMode("optin")
