import { Controller } from "stimulus"

export default class extends Controller {

  load(event) {
    event.preventDefault()

    const url = event.currentTarget.href
    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    }).then(response => {
      return response.json()
    }).then(json => {
      this.element.innerHTML = json.html
    })
  }
}
