import { Controller } from "stimulus"
import  Quill  from "quill"
import  MarkdownIt  from "markdown-it"
import  TurndownService  from "turndown"
import "quill/dist/quill.snow.css"
import SmallBlot from "vendor/quill/small"

export default class extends Controller {

  static values = {
    inputFormat: String,
    outputFormat: String,
    toolbarButtons: Array
  }

  connect() {
    this.initTurndown()
    this.initMarkdownIt()

    const input = this.element.querySelector("textarea") || 
      this.element.querySelector("input") 

    this.output = document.createElement("input")
    this.output.type = "hidden"
    this.output.name = input.name
    this.output.value = input.value
    this.output.id = input.id
    this.element.after(this.output)
    input.parentNode.removeChild(input)
    this.initQuill()
  }

  initTurndown() {
    this.turndownService = new TurndownService()

    this.turndownService.addRule("small", {
      filter: "small",
      replacement: (content) => {
        return `~~${content}~~`
      }
    })
  }

  initMarkdownIt() {
    this.markdownIt = new MarkdownIt({
      html: true
    })
  }

  initQuill(inputFormat = "md") {
    Quill.register(SmallBlot)
    const icons = Quill.import("ui/icons")
    icons["strike"] = "<span>small</span>"
    icons["link"] = "<span>link</span>"

    this.quill = new Quill(this.element, {
      theme: "snow",
      modules: {
        toolbar: this.quillToolbarOptions
      }
    })

    const initValue = this.convert(this.output.value, this.inputFormat, "html")
    this.quill.root.innerHTML = ""
    this.quill.clipboard.dangerouslyPasteHTML(0, initValue)

    this.quill.on("text-change", (delta, oldDelta, source) => {
      const innerHTML = this.quill.root.innerHTML
      this.output.value = this.convert(innerHTML, "html", this.outputFormat)
    })
  }

  convert(value, inputFormat = "md", outputFormat = "html") {
    if (inputFormat === "md" && outputFormat === "html") {
      return this.markdownIt.render(value)
    } else if (inputFormat == "html" && outputFormat === "md") {
      return this.turndownService.turndown(value)
    }
  }

  get inputFormat() {
    return this.inputFormatValue.length > 0 ? this.inputFormatValue : "md"
  }

  get outputFormat() {
    return this.outputFormatValue.length > 0 ? this.outputFormatValue : "md"
  }

  get quillToolbarOptions() {
    const container = this.toolbarButtonsValue
    if (container.length === 0) { return false }

    return {
      container: container,
      handlers: {
        "strike": function(value) {
          this.quill.format("strike", value)
        }
      }
    }
  }
}
