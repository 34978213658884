import { Controller } from "stimulus"

export default class extends Controller { 

  static values = {
    url: String,
    active: Boolean
  }

  static targets = ["fullscreen"]

  connect() {
    if (!this.matchMedia()) { return }

    this.activeValue = this.activeValue

    const className = "slide"
    this.element.classList.add(className)

    this.clickHandler = (event) => {

      if (this.element.contains(event.target)) {
        this.toggle()
      } else if (this.activeValue) {
        this.toggle(false)
      }
    }

    document.addEventListener("click", this.clickHandler, true)
  }

  disconnect() {
    document.removeEventListener("click", this.clickHandler, true)
  }

  toggle(value = null) {

    if (value === null) {
      value  = !this.activeValue
    } 

    this.activeValue = value
  }

  activeValueChanged() {
    if (this.activeValue && !this.hasFullscreenTarget) {

      this.fetch()
        .then(html => {
          const fullscreen = document.createElement("div")
          fullscreen.classList.add("slide__fullscreen")
          fullscreen.setAttribute("data-slide-target", "fullscreen")
          fullscreen.innerHTML = html
          this.element.append(fullscreen)
        })

    } else if (this.hasFullscreenTarget) {
      this.fullscreenTarget.parentNode.removeChild(this.fullscreenTarget)
    }

    const event = new CustomEvent("slide:toggle", { 
      bubbles: true, 
      detail: this.activeValue 
    })

    this.element.dispatchEvent(event)
  }

  async fetch() {
    const response = await fetch(this.urlValue, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    })

    const json = await response.json()
    return json.html
  }

  matchMedia() {
    const matchMedia = window.matchMedia("(min-width: 769px)")
    return matchMedia.matches
  }
}
